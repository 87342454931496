import React, { useState, useEffect } from 'react'
import { Title } from '../../components'
import { StyledForms2, FlexBox, Flex } from './Forms.styles'
import { FormDatosUsuario, InputError } from './index'
import { FormNuevoViaje } from './FormNuevoViaje/FormNuevoViaje'
// import { StyledPuntos, StyledLi } from '../Mainpage/puntos'
import useCounter from '../../hooks/useCounter'
import useForm from '../../hooks/useInfo'
import { useSelector } from 'react-redux'
// import googleService from '../../services/googleService'
import { useNavigate } from 'react-router-dom'

const Forms = () => {
  const index = useCounter()
  const [success, setSuccess] = useState(false)
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   googleService.checkUser(dispatch)
  // }
  // , [])

  useEffect(() => {
    handleNext()
  }, [success])

  const handleSuccess = (state) => {
    setSuccess(state)
  }

  const handleNext = () => {
    if (index.getValue() === 0 && success) {
      navigate('/pasajes')
    }
  }

  const info = useForm({
    nombre: '',
    apellido: '',
    apodo: '',
    dni: '',
    nroTramite: '',
    genero: '',
    codArea: '',
    numero: '',
    correo: ''
  })

  const viaje = useForm({
    origen: 'Mar del Plata',
    destino: 'Buenos Aires',
    pasajeros: '1',
    fecha: null
  })

  const renderForm = () => {
    // if (!user.limitePasajes) {
    if (user.isValidate) {
      return <FormNuevoViaje success = {success} handleSuccess = {handleSuccess} handleNext = {handleNext} viaje = {viaje}></FormNuevoViaje>
    }
    if (user.isValidate == null) { return <p>Cargando...</p> }
    return <FormDatosUsuario success = {success} handleSuccess = {handleSuccess} handleNext = {handleNext} info = {info} ></FormDatosUsuario>
    // } else {
    //   return <h4>El limite de pasajes por persona es de 4.<br></br> Si desea realizar algun cambio pongase en contacto con Servicio al Cliente</h4>
    // }
  }

  const renderTitle = () => {
    // if (!user.limitePasajes) {
    if (user.isValidate) {
      switch (index.getValue()) {
        case (0):
          return <h4> Ingrese los datos de su viaje</h4>
        case (1):
          return <h4> Limite de pasajes alcanzado</h4>
      }
    }
    if (user.isValidate == null) { return <h1></h1> }
    return <h4>Ingrese los siguientes datos personales</h4>
    // } else {
    //   return <h4> Limite de pasajes alcanzado</h4>
    // }
  }

  return (
    <FlexBox>
      <StyledForms2>
        <Flex>
          <Title name = 'Boletería'></Title>
          <InputError message = "Ya podes encargar tu pasaje para Agosto!"></InputError>
          {renderTitle()}
        </Flex>
        {renderForm()}
      </StyledForms2>
    </FlexBox>
  )
}

export default Forms
